import { useState, useCallback } from 'react'
import {
  Card,
  Box,
  Text,
  Autocomplete,
  BlockStack,
  Icon,
  InlineStack,
  Button,
  InlineError
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { SearchIcon, XSmallIcon } from '@shopify/polaris-icons'

export const BomCard = ({
  bom,
  showRequiredError,
  onSelectOutputProduct,
  onRemoveOutputProduct
}) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = useCallback((value) => {
    setInputValue(value)
    if (value.length > 0) {
      onSelectOutputProduct(value)
      setInputValue('')
    }
  }, [onSelectOutputProduct])

  const outputProduct = bom?.output_product

  return (
    <Card>
      <BlockStack gap='200'>
        <Text as="h2" variant="headingMd">
          {t('bundles.bundle')}
        </Text>
        {!outputProduct && (
          <BlockStack gap='100'>
            <Autocomplete
              id="outputProductSearch"
              options={[]}
              selected={[]}
              onSelect={() => {}}
              textField={
                <Autocomplete.TextField
                  onChange={handleInputChange}
                  value={inputValue}
                  placeholder={t('bundles.search_for_products')}
                  autoComplete="off"
                  prefix={<Icon source={SearchIcon} />}
                />
              }
            />
            {!!showRequiredError && (
              <InlineError
                message={t('bundles.bundled_product_required')}
                fieldID="outputProductSearch"
              />
            )}
          </BlockStack>
        )}
      </BlockStack>
      {outputProduct && (
        <InlineStack align="space-between">
          <Box padding="200" borderColor="black">
            <Text variant="bodyMd" fontWeight="bold" as="h3">
              {outputProduct.product_name}
            </Text>
            <Text variant="bodySm" color="subdued">
              {outputProduct.product_variant_name}
            </Text>
          </Box>
          <Button
            onClick={() => onRemoveOutputProduct()}
            plain
            icon={XSmallIcon}
            variant="tertiary"
          >
          </Button>
        </InlineStack>
      )}
    </Card>
  )
}
