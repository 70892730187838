import { Footer } from '@shared/components/Footer'
import { NewReleaseBanner } from '@shared/components/NewReleaseBanner'

function ShopifyLayout ({ children }) {
  return (
    <div>
      <div>
        <NewReleaseBanner />
        {children}
        <Footer />
      </div>
    </div>
  )
}

export default ShopifyLayout
