export const formatTooltip = (template, vars) => {
  if (!vars) return template.split('\n')

  let formattedText = template

  // Replace each var placeholder with a value from vars stack
  let varIndex = 0
  const varPlaceholder = '{{VAR}}'

  while (formattedText.includes(varPlaceholder) && varIndex < vars.length) {
    formattedText = formattedText.replace(varPlaceholder, vars[varIndex++])
  }

  return formattedText.split('\n')
}
