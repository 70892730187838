import React from 'react'
import '@shared/styles/forecast.css'
import demo from '@standalone/img/demo/supply_forecast.png'

const SalesAnalyticsView = () => {
  return (
    <div className='dashboard-container'>

      <div className='forecast-section'>
        <img src={demo} alt='supply forecast' />
      </div>
    </div>
  )
}

export default SalesAnalyticsView
