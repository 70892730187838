import { backendRequest } from '@shared/utils/axiosInstance'

export const fetchLocations = async (companyId, appBridge) => {
  try {
    const response = await backendRequest({
      method: 'GET',
      url: `/obius/locations?company_id=${companyId}`,
      data: {},
      appBridge
    })
    return response.data
  } catch (error) {
    throw new Error('Error fetching locations by company ID: ' + error.message)
  }
}
