import React, { useState, useCallback } from 'react'
import { TextField } from '@shopify/polaris'

export const EditableCell = ({
  initialValue,
  onSave,
  placeholder,
  helpText,
  label,
  digit = 0,
  selectTextOnFocus = true,
  autoFocus = false,
  step = '1',
  suffix,
  minValue = 0
}) => {
  const [value, setValue] = useState(initialValue)

  const handleChange = useCallback((newValue) => {
    if (!newValue || isNaN(newValue)) return setValue('')
    if (+newValue < minValue) return setValue(`${minValue}`)
    setValue(newValue)
  }, [minValue])

  const handleBlur = useCallback(() => {
    const parseValue = +parseFloat(value, 10).toFixed(digit)
    setValue(`${parseValue}`)
    onSave(parseValue)
  }, [onSave, value, digit])

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      const inputs = Array.from(document.querySelectorAll('input'))
      const currentIndex = inputs.indexOf(event.target)
      const nextInput = inputs[currentIndex + 1]
      if (nextInput) {
        nextInput.focus()
      }
    }
  }, [])

  return (
    <div onKeyDown={handleKeyDown}>
      <TextField
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        helpText={helpText}
        label={label}
        autoComplete="off"
        autoFocus={autoFocus}
        type='number'
        min={`${minValue}`}
        step={step}
        selectTextOnFocus={selectTextOnFocus}
        suffix={suffix}
      />
    </div>
  )
}
