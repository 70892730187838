import React from 'react'
import { Page, Text, BlockStack } from '@shopify/polaris'

const ErrorPage = ({ errorCode }) => {
  return (
    <Page>
      <BlockStack align="center" inlineAlign="center">
        <Text as='h1' variant='heading3xl'>
          {errorCode}
        </Text>
      </BlockStack>
    </Page>
  )
}

export default ErrorPage
