import { Banner, Icon, Text, InlineStack, BlockStack } from '@shopify/polaris'
import React, { Fragment, useState } from 'react'
import { LockIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { formatTooltip } from '@shared/utils/tooltipFormatter'

export const UpgradeBanner = ({
  tone,
  title,
  description,
  descriptionVars = null,
  secondaryDescription,
  secondaryDescriptionVars = null,
  onDismissCallback = () => {},
  isShown = true
}) => {
  const [show, setIsShow] = useState(isShown)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const formattedDescription = descriptionVars ? formatTooltip(description, descriptionVars) : description
  const formattedSecondaryDescription = secondaryDescriptionVars ? formatTooltip(secondaryDescription, secondaryDescriptionVars) : secondaryDescription

  const handleDismiss = () => {
    setIsShow(false)
    onDismissCallback()
  }

  const handleUpgrade = () => {
    navigate('/plan-selection')
  }

  return (
    show && (
      <Fragment>
        <Banner
          tone={tone}
          title={title}
          onDismiss={handleDismiss}
          action={{
            content: (
              <InlineStack
                blockAlign='center'
              >
                <Icon source={LockIcon} />
                <Text>{t('button.upgrade_now')}</Text>
              </InlineStack>
            ),
            onClick: handleUpgrade
          }}
      >
          <BlockStack gap="200">
            <Text>{formattedDescription}</Text>
            <Text>{formattedSecondaryDescription}</Text>
          </BlockStack>
        </Banner>
      </Fragment>
    )
  )
}
