import { useState, useCallback, useMemo, useEffect } from 'react'
import {
  Card,
  Text,
  BlockStack,
  Listbox,
  Combobox,
  Icon,
  InlineError
} from '@shopify/polaris'
import { CaretDownIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'

export const LocationSelectionCard = ({
  onChange,
  locations,
  isLocationError,
  locationId = null,
  isReadonly = false
}) => {
  const { t } = useTranslation()

  const deselectedOptions = useMemo(() => {
    if (!locations) return []

    const locationOptions = locations.map(location => ({
      value: location.location_id,
      label: location.location_name
    }))

    return locationOptions
  }, [locations])

  const [selectedLocation, setSelectedLocation] = useState(locationId)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState(deselectedOptions)

  useEffect(() => {
    if (!locationId || !Array.isArray(locations)) return

    setInputValue(locations.find((location) => location.location_id === locationId)?.location_name)
  }, [locationId, locations])

  const escapeSpecialRegExCharacters = useCallback((value) => value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), [])

  const updateText = useCallback((value) => {
    setInputValue(value)

    if (value === '') {
      setOptions(deselectedOptions)
      return
    }

    const filterRegex = new RegExp(escapeSpecialRegExCharacters(value), 'i')
    const resultOptions = deselectedOptions.filter((option) => option.label.match(filterRegex))
    setOptions(resultOptions)
  }, [deselectedOptions, escapeSpecialRegExCharacters])

  const updateSelection = useCallback((selected) => {
    const matchedOption = options.find((option) => option.value.match(selected))
    onChange(matchedOption?.value)
    setSelectedLocation(selected)
    setInputValue(matchedOption?.label || '')
  }, [options, onChange])

  const optionsMarkup =
    options.length > 0
      ? options.map((option) => {
        const { label, value } = option
        return (
          <Listbox.Option
            key={`${value}`}
            value={value}
            selected={selectedLocation === value}
            accessibilityLabel={label}
          >
            {label}
          </Listbox.Option>
        )
      })
      : null

  return (
    <Card>
      <BlockStack gap='200'>
        <Text as="h2" variant="headingMd">
          {t('location_selection_card.title')}
        </Text>
        {isReadonly
          ? (
            <Text>
              {inputValue}
            </Text>
            )
          : (
            <BlockStack gap='100'>
              <Combobox
                activator={
                  <Combobox.TextField
                    fieldID="location"
                    selectTextOnFocus={false}
                    suffix={<Icon source={CaretDownIcon} />}
                    onChange={updateText}
                    value={inputValue}
                    placeholder={t('location_selection_card.placeholder')}
                    autoComplete="off"
                    />
                  }
                >
                {options.length > 0
                  ? (
                    <Listbox onSelect={updateSelection}>{optionsMarkup}</Listbox>
                    )
                  : null}
              </Combobox>
              {(isLocationError) && (
              <InlineError
                message={t('location_selection_card.required')}
                fieldID="location"
                  />
              )}
            </BlockStack>
            )
        }
      </BlockStack>
    </Card>
  )
}
