exports.PRODUCT_STATUS_DEFAULT_NOT_IN_FILTER = ['archived', 'draft']
exports.INVENTORY_TRACKED_DEFAULT_NOT_IN_FILTER = ['f', 'false']
exports.LOCATION_NAME_DEFAULT_NOT_IN_FILTER = ['Deleted Location']
exports.DEFAULT_LEAD_TIME = 15
exports.DEFAULT_MOQ = 0
exports.DEFAULT_MIN_DAYS_INVENTORY = 15
exports.DAILY_VELOCITY_THRESHOLD = 0.14
exports.REPLENISHMENT_SOON_DAY_THRESHOLD = 30
exports.REPLENISHMENT_NOW_DAY_THRESHOLD = 0
exports.LARGE_REQUEST_LIMIT = 10000
exports.HELP_DESK_URL = 'https://obius.atlassian.net/servicedesk/customer/portal/1'
exports.OBIUS_URL = 'https://obius.co'
exports.SUBSCRIPTION_PLAN = {
  FREE: 'free',
  PREMIUM: 'premium'
}
