import { Card, Text, BlockStack, Link, InlineStack, Badge, Thumbnail } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import stockHeroDarkImage from '@shared/assets/stockhero-dark.jpg'
import stockHeroLightImage from '@shared/assets/stockhero-light.jpg'

export const EcosystemCard = ({ isBomInstalled, isReplenInstalled }) => {
  const { t } = useTranslation()

  return (
    <Card sectioned>
      <BlockStack gap='300' inlineAlign="start">
        <Text variant='headingXl' as="h2">
          {t('ecosystem_card.title')}
        </Text>
        <Text variant="headingSm" as="p">
          {t('ecosystem_card.subtitle')}
        </Text>
        <InlineStack gap='300' blockAlign='start' wrap={false}>
          <Thumbnail
            source={stockHeroDarkImage}
            alt={t('ecosystem_card.bom.title')}
          />
          <BlockStack gap='100' inlineAlign="start" align="start">
            <InlineStack gap='300' blockAlign='center'>
              <Link url="https://apps.shopify.com/obius-bundle-inventory" target="_blank">
                {t('ecosystem_card.bom.title')}
              </Link>
              {isBomInstalled && (
                <Badge tone="success">
                  {t('ecosystem_card.installed')}
                </Badge>
              )}
            </InlineStack>
            <Text as="p">
              {t('ecosystem_card.bom.description')}
            </Text>
          </BlockStack>
        </InlineStack>
        <InlineStack gap='300' blockAlign='start' wrap={false}>
          <Thumbnail
            source={stockHeroLightImage}
            alt={t('ecosystem_card.replen.title')}
          />
          <BlockStack gap='100' inlineAlign="start" align="start">
            <InlineStack gap='300' blockAlign='center'>
              <Link url="https://apps.shopify.com/obius-inventory-optimization" target="_blank">
                {t('ecosystem_card.replen.title')}
              </Link>
              {isReplenInstalled && (
                <Badge tone="success">
                  {t('ecosystem_card.installed')}
                </Badge>
              )}
            </InlineStack>
            <Text as="p">
              {t('ecosystem_card.replen.description')}
            </Text>
          </BlockStack>
        </InlineStack>
      </BlockStack>
    </Card>
  )
}

export default EcosystemCard
