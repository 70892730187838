import { useCallback } from 'react'
import {
  ResourceList,
  ResourceItem,
  Text,
  Layout,
  Page,
  Card,
  EmptyState,
  SkeletonPage,
  SkeletonBodyText,
  SkeletonDisplayText,
  BlockStack,
  Box,
  Filters,
  InlineStack,
  Badge
} from '@shopify/polaris'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useBomsList } from '@bom/hooks/useBomsList'
import { UpgradeButton } from '@shared/components/UpgradeButton'

export const BundleListView = () => {
  const { t } = useTranslation()
  const {
    boms,
    currentPageBoms,
    isLoading,
    error,
    handleNextPage,
    handlePreviousPage,
    hasNextPage,
    hasPreviousPage,
    handleFiltersQueryChange,
    queryValue,
    isCreateButtonDisabled
  } = useBomsList()
  const navigate = useNavigate()

  const handleCreate = useCallback(() => {
    navigate('/bundles/new')
  }, [navigate])

  const emptyStateMarkup = (
    <EmptyState
      heading={t('bundles.create_bundles_to_get_started')}
      action={{ content: t('bundles.create_bundles'), onAction: handleCreate }}
      image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
    >
      <p>
        {t('bundles.contact_support_to_upload_bundles')}
      </p>
    </EmptyState>
  )

  if (isLoading) {
    return (
      <SkeletonPage primaryAction>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <SkeletonDisplayText size="large" />
              <BlockStack gap='200'>
                <SkeletonBodyText />
                <SkeletonBodyText />
                <SkeletonBodyText />
                <SkeletonBodyText />
                <SkeletonBodyText />
              </BlockStack>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    )
  }

  if (error) {
    return (
      <Page title={t('bundles.bundles')}>
        <Card>
          <Text variant="bodyMd" color="critical">{t('bundles.error')}: {error}</Text>
        </Card>
      </Page>
    )
  }

  return (
    <Page
      title={t('bundles.bundles')}
      primaryAction={{
        content: t('bundles.create_bundle'),
        onAction: handleCreate,
        disabled: isCreateButtonDisabled
      }}
      secondaryActions={ <UpgradeButton hidden={!isCreateButtonDisabled} /> }
      divider
      >
      <Card>
        <Layout>
          <Layout.Section>
            <Card>
              <ResourceList
                filterControl={
                  <Filters
                    filters={[]}
                    queryValue={queryValue}
                    onQueryChange={handleFiltersQueryChange}
                  />
                }
                emptyState={boms.length === 0 ? emptyStateMarkup : undefined}
                resourceName={{ singular: t('bundles.bundle'), plural: t('bundles.bundles') }}
                items={currentPageBoms}
                renderItem={(item) => {
                  const bomId = item.bom_id
                  const outputProductName = item.output_product.product_name
                  const productVariant = item.output_product.product_variant_name
                  const bomStatus = item.status

                  return (
                    <ResourceItem
                      id={bomId}
                      onClick={() => {
                        navigate(`/bundles/${bomId}`)
                      }}
                      accessibilityLabel={`${t('bundles.view_details_for')} ${outputProductName}`}
                    >
                      <InlineStack gap='200' blockAlign="center" align="space-between">
                        <Box>
                          <Text variant="bodyMd" fontWeight="bold" as="h3">
                            {outputProductName}
                          </Text>
                          <div>{productVariant}</div>
                        </Box>
                        <Badge tone={bomStatus === 'active' ? 'success' : 'info'}>
                          {t(`status_card.statuses.${bomStatus}`)}
                        </Badge>
                      </InlineStack>
                    </ResourceItem>
                  )
                }}
                pagination={{
                  hasNext: hasNextPage,
                  onNext: handleNextPage,
                  hasPrevious: hasPreviousPage,
                  onPrevious: handlePreviousPage
                }}
              />
            </Card>
          </Layout.Section>
        </Layout>
      </Card>
    </Page>
  )
}
