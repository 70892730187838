import { useTranslation } from 'react-i18next'
import { useState, useCallback, useMemo, useContext } from 'react'
import {
  Page,
  Button,
  TextField,
  Frame,
  BlockStack,
  SkeletonPage,
  SkeletonBodyText,
  Layout,
  Card
} from '@shopify/polaris'
import { useNavigate } from 'react-router-dom'
import Options from '@replen/components/Options'
import { useAlerts } from '@replen/hooks/useAlerts'
import SettingsSection from '@shared/components/SettingsSection'
import CustomToast from '@shared/components/CustomToast'
import { DataUpdateContext } from '@shared/context/context'
import { hoursOptions } from '@shared/utils'
import { EditableCell } from '@shared/components/EditableCell'
import { UpgradeButton } from '@shared/components/UpgradeButton'
import {
  REPLENISHMENT_SOON_DAY_THRESHOLD,
  DAILY_VELOCITY_THRESHOLD,
  REPLENISHMENT_NOW_DAY_THRESHOLD,
  SUBSCRIPTION_PLAN
} from '@shared/utils/constant'

export const SettingView = () => {
  const { subscriptionPlan } = useContext(DataUpdateContext)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    selectedDayOptions,
    setSelectedDayOptions,
    selectedHourOptions,
    setSelectedHourOptions,
    emailAddress,
    setEmailAddress,
    loading,
    error,
    saveSettings,
    dailyVelocityThreshold,
    setDailyVelocityThreshold,
    replenishNowDayThreshold,
    setReplenishNowDayThreshold,
    replenishSoonDayThreshold,
    setReplenishSoonDayThreshold,
    leadTime,
    setLeadTime,
    minDaysInventory,
    setMinDaysInventory
  } = useAlerts()

  const [isSaving, setIsSaving] = useState(false)
  const [toastState, setToastState] = useState({
    active: false,
    content: '',
    error: false
  })

  const showToast = (content, error = false) => {
    setToastState({ active: true, content, error })
  }

  const hideToast = useCallback(() => {
    setToastState({ active: false, content: '', error: false })
  }, [])

  const handleNavigateToPlanSelection = () => {
    navigate('/plan-selection')
  }

  const handleEmailAddressChange = useCallback((value) => {
    setEmailAddress(value)
  }, [setEmailAddress])

  const handleDailyVelocityThresholdChange = useCallback((value) => {
    setDailyVelocityThreshold(+value)
  }, [setDailyVelocityThreshold])

  const handleReplenishNowDayThresholdChange = useCallback((value) => {
    setReplenishNowDayThreshold(+value)
  }, [setReplenishNowDayThreshold])

  const handleReplenishSoonDayThresholdChange = useCallback((value) => {
    setReplenishSoonDayThreshold(+value)
  }, [setReplenishSoonDayThreshold])

  const handleLeadTimeChange = useCallback((value) => {
    setLeadTime(+value)
  }, [setLeadTime])

  const handleMinDaysInventoryChange = useCallback((value) => {
    setMinDaysInventory(+value)
  }, [setMinDaysInventory])

  const handleSaveSettings = async () => {
    setIsSaving(true)
    const result = await saveSettings()
    showToast(t(result.success ? 'settings.save_success' : 'settings.save_error'), !result.success)
    setIsSaving(false)
  }

  const hourDeSelectionOptions = useMemo(() => hoursOptions, [])
  const dayDeSelectionOptions = useMemo(
    () => [
      { label: t('days.1'), value: 1 },
      { label: t('days.2'), value: 2 },
      { label: t('days.3'), value: 3 },
      { label: t('days.4'), value: 4 },
      { label: t('days.5'), value: 5 },
      { label: t('days.6'), value: 6 },
      { label: t('days.0'), value: 0 }
    ],
    [t]
  )
  const [daySelectionOptions, setDaySelectionOptions] = useState(dayDeSelectionOptions)
  const [hourSelectionOptions, setHourSelectionOptions] = useState(hourDeSelectionOptions)

  if (loading) {
    return (
      <SkeletonPage title={t('sidebar.settings')}>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <SkeletonBodyText lines={2} />
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card sectioned>
              <SkeletonBodyText lines={4} />
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card sectioned>
              <SkeletonBodyText lines={2} />
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    )
  }

  if (error) {
    return <div>{t('common.error', { error })}</div>
  }

  return (
    <Frame>
      <Page
        title={t('sidebar.settings')}
        divider
        primaryAction={{
          content: t('settings.save_settings'),
          disabled: subscriptionPlan === SUBSCRIPTION_PLAN.FREE || isSaving,
          onAction: handleSaveSettings,
          loading: isSaving
        }}
        secondaryActions={
          <UpgradeButton
            hidden={subscriptionPlan === SUBSCRIPTION_PLAN.PREMIUM}
            tooltip={t('tooltip.unlock_settings')}
          />
        }
      >
        <BlockStack gap={{ xs: '800', sm: '400' }}>
          <SettingsSection
            title={t('settings.alerts.title')}
            description={t('settings.alerts.description')}
          >
            <TextField
              label={t('settings.alerts.email_label')}
              value={emailAddress}
              onChange={handleEmailAddressChange}
              autoComplete="email"
            />
            <Options
              selectionOptions={daySelectionOptions}
              setSelectionOptions={setDaySelectionOptions}
              setSelectedOptions={setSelectedDayOptions}
              selectedOptions={selectedDayOptions}
              deSelectionOptions={dayDeSelectionOptions}
              comboLabel={t('settings.schedule.select_days')}
              comboPlaceholder={t('settings.schedule.select_days_placeholder')}
              allowMultiple={true}
              isTime={false}
            />
            <Options
              selectionOptions={hourSelectionOptions}
              setSelectionOptions={setHourSelectionOptions}
              setSelectedOptions={setSelectedHourOptions}
              selectedOptions={selectedHourOptions}
              deSelectionOptions={hourDeSelectionOptions}
              comboLabel={t('settings.schedule.select_hour')}
              comboPlaceholder={t('settings.schedule.select_hour_placeholder')}
              allowMultiple={false}
              isTime={true}
            />
          </SettingsSection>

          <SettingsSection
            title={t('settings.company_params.title')}
            description={t('settings.company_params.description')}
          >
            <EditableCell
              label={t('settings.company_params.daily_velocity_threshold.label')}
              initialValue={dailyVelocityThreshold}
              placeholder={`${DAILY_VELOCITY_THRESHOLD}`}
              digit="2"
              step = "0.01"
              autoFocus={false}
              onSave={(newValue) => handleDailyVelocityThresholdChange(newValue)}
              helpText={t('settings.company_params.daily_velocity_threshold.help_text')}
              suffix={t('units.units')}
            />
            <EditableCell
              label={t('settings.company_params.replenish_now_day_threshold.label')}
              initialValue={replenishNowDayThreshold}
              placeholder={`${REPLENISHMENT_NOW_DAY_THRESHOLD}`}
              autoFocus={false}
              onSave={(newValue) => handleReplenishNowDayThresholdChange(newValue)}
              helpText={t('settings.company_params.replenish_now_day_threshold.help_text')}
              suffix={t('units.days')}
            />
            <EditableCell
              label={t('settings.company_params.replenish_soon_day_threshold.label')}
              initialValue={replenishSoonDayThreshold}
              placeholder={`${REPLENISHMENT_SOON_DAY_THRESHOLD}`}
              autoFocus={false}
              onSave={(newValue) => handleReplenishSoonDayThresholdChange(newValue)}
              helpText={t('settings.company_params.replenish_soon_day_threshold.help_text')}
              suffix={t('units.days')}
            />
            <EditableCell
              label={t('settings.company_params.lead_time.label')}
              initialValue={leadTime}
              placeholder="15"
              autoFocus={false}
              onSave={(newValue) => handleLeadTimeChange(newValue)}
              helpText={t('settings.company_params.lead_time.help_text')}
              suffix={t('units.days')}
            />
            <EditableCell
              label={t('settings.company_params.min_days_inventory.label')}
              initialValue={minDaysInventory}
              placeholder="15"
              autoFocus={false}
              onSave={(newValue) => handleMinDaysInventoryChange(newValue)}
              helpText={t('settings.company_params.min_days_inventory.help_text')}
              suffix={t('units.days')}
              minValue={1}
            />
          </SettingsSection>

          <SettingsSection
            title={t('settings.subscriptions.title')}
            description={t('settings.subscriptions.description')}
          >
            <Button onClick={handleNavigateToPlanSelection}>{t('settings.subscriptions.manage_plan')}</Button>
          </SettingsSection>
        </BlockStack>
        <CustomToast
          active={toastState.active}
          content={toastState.content}
          error={toastState.error}
          onDismiss={hideToast}
        />
      </Page>
    </Frame>
  )
}
