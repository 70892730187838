import { useState, useCallback } from 'react'
import {
  Card,
  BlockStack,
  IndexTable,
  Button,
  EmptySearchResult,
  Text,
  Autocomplete,
  InlineGrid,
  Icon,
  InlineError
} from '@shopify/polaris'
import { SearchIcon, XSmallIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'
import { EditableCell } from '@shared/components/EditableCell'

export const AddMaterialsCard = ({
  materials,
  onSearchChange,
  onRemoveMaterial,
  searchLabel,
  onSelectProduct,
  showRequiredError,
  onQuantityChange = () => {},
  isQuantityReadonly = false
}) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = useCallback((value) => {
    setInputValue(value)
    if (value.length > 0) {
      onSelectProduct(value)
      setInputValue('')
    }
  }, [onSelectProduct])

  const emptyStateMarkup = <EmptySearchResult />

  return (
    <Card title={t('bundles.materials')}>
      <BlockStack gap='400'>
        <BlockStack gap='200'>
          <Text as="h2" variant="headingMd">
            {t('bundles.add_materials')}
          </Text>
          <BlockStack gap="100">
            <InlineGrid columns="1fr auto" gap="100">
              <Autocomplete
                id="materialSearch"
                options={[]}
                selected={[]}
                onSelect={() => {}}
                textField={
                  <Autocomplete.TextField
                    onChange={handleInputChange}
                    value={inputValue}
                    placeholder={t('bundles.search_for_products')}
                    autoComplete="off"
                    prefix={<Icon source={SearchIcon} />}
                  />
                }
              />
              <Button
                onClick={onSearchChange}
                accessibilityLabel={searchLabel}
                size='large'
              >
                {t('button.browse')}
              </Button>
            </InlineGrid>
            { showRequiredError && (
              <InlineError
                message={t('bundles.material_required')}
                fieldID="materialSearch"
              />
            )}
          </BlockStack>
        </BlockStack>
        <IndexTable
          itemCount={materials.length}
          emptyState={emptyStateMarkup}
          headings={[
            { title: t('bundles.product') },
            { title: t('bundles.quantity') },
            { title: '' }
          ]}
          selectable={false}
        >
          {materials.map((material, index) => (
            <IndexTable.Row
              id={material.material_id}
              key={material.material_id}
              position={index}
            >
              <IndexTable.Cell>
                <BlockStack gap="100">
                  <Text variant="bodyMd" fontWeight="bold">
                    {material.product.product_name}
                  </Text>
                  {material.product.product_variant_name && (
                    <Text variant="bodySm" color="subdued">
                      {material.product.product_variant_name}
                    </Text>
                  )}
                </BlockStack>
              </IndexTable.Cell>
              <IndexTable.Cell>
                {isQuantityReadonly
                  ? (
                    <Text>
                      {material.quantity}
                    </Text>
                    )
                  : (
                    <>
                      <EditableCell
                        fieldID={`quantity_${material.material_id}`}
                        initialValue={material.quantity.toString()}
                        onSave={(newValue) => onQuantityChange(material.material_id, newValue)}
                        minValue="1"
                        />
                      {(!material.quantity || isNaN(material.quantity)) && (
                      <InlineError
                        message={t('bundles.quantity_required')}
                        fieldID={`quantity_${material.material_id}`}
                          />
                      )}
                    </>
                    )
                }
              </IndexTable.Cell>
              <IndexTable.Cell>
                <Button
                  onClick={() => onRemoveMaterial(material.material_id, material.product_id)}
                  plain
                  icon={XSmallIcon}
                  variant="tertiary"
                  accessibilityLabel={t('bundles.remove_material', { name: material.product.product_name })}
                >
                </Button>
              </IndexTable.Cell>
            </IndexTable.Row>
          ))}
        </IndexTable>
      </BlockStack>
    </Card>
  )
}
