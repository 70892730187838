import { Box, Tooltip, BlockStack, Text, Button } from '@shopify/polaris'
import { LockIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const UpgradeButton = ({ hidden, tooltip }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  if (hidden) return null
  if (!tooltip) tooltip = t('tooltip.unlock_unlimited_bundles')

  const handleNavigateToPlanSelection = () => {
    navigate('/plan-selection')
  }

  return (
    <Tooltip
      content={
        <Box padding='200' paddingBlockStart='300' paddingBlockEnd='300'>
          <BlockStack gap='300'>
            <Text variant="headingMd">
              {t('tooltip.unlock_title')}
            </Text>
            <Text>
              {tooltip}
            </Text>
          </BlockStack>
        </Box>
      }
      preferredPosition='below'
    >
      <Button onClick={handleNavigateToPlanSelection} icon={LockIcon}>{t('button.upgrade')}</Button>
    </Tooltip>
  )
}

export default UpgradeButton
