import { FooterHelp, Link } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { HELP_DESK_URL } from '@shared/utils/constant'

export const Footer = () => {
  const { t } = useTranslation()
  return (
    <FooterHelp>
      {t('footer.help')}
      <Link url={HELP_DESK_URL} target="_blank">
        {t('footer.link')}
      </Link>
    </FooterHelp>
  )
}
