import { backendRequest } from '@shared/utils/axiosInstance'

export const fetchProductionsList = async (companyId, limit, offset, appBridge) => {
  try {
    const response = await backendRequest({
      method: 'GET',
      url: `/obius/productions?company_id=${companyId}&source=manual&limit=${limit}&skip=${offset}&populate=output_product&sort=-production_date`,
      data: {},
      appBridge
    })
    return response.data
  } catch (error) {
    throw new Error('Error fetching productions\' list: ' + error.message)
  }
}

export const fetchProduction = async (productionId, appBridge) => {
  try {
    const response = await backendRequest({
      method: 'GET',
      url: `/obius/productions/${productionId}?populate=output_product,consumptions`,
      data: {},
      appBridge
    })
    return response.data
  } catch (error) {
    throw new Error('Error fetching production' + error.message)
  }
}

export const updateProduction = async (data, appBridge) => {
  try {
    const response = await backendRequest({
      method: 'PUT',
      url: `/obius/productions/${data.production_id}`,
      data,
      appBridge
    })
    return response.data
  } catch (error) {
    throw new Error('Error updating Production: ' + error.message)
  }
}
