import '@shopify/polaris/build/esm/styles.css'
import { useContext, useState } from 'react'
import { Page, InlineStack, Card, SkeletonPage, Box } from '@shopify/polaris'
import { useNavigate } from 'react-router-dom'
import { backendRequest } from '@shared/utils/axiosInstance'
import { DataUpdateContext } from '@shared/context/context'
import { PlanCard } from '@shared/components/PlanCard'
import { useTranslation } from 'react-i18next'

export const PlanSelectionView = () => {
  const { companyId, appBridge, subscriptionPlan } = useContext(DataUpdateContext)
  const [loadingPremiumPlan, setLoadingPremiumPlan] = useState(false)
  const [loadingFreePlan, setLoadingFreePlan] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleFreePlanSelection = async () => {
    setLoadingFreePlan(true)
    try {
      const { data: { return_url: returnUrl } } = await backendRequest({
        method: 'POST',
        url: '/shopify/subscriptions',
        appBridge,
        data: {
          company_id: companyId,
          plan: 'free'
        }
      })
      navigate(`/shopify/subscription/return?${returnUrl.split('?')[1]}`)
    } catch (error) {
      console.error('Failed to create free subscription.', error)
    } finally {
      setLoadingFreePlan(false)
    }
  }

  const handlePremiumPlanSelection = async () => {
    setLoadingPremiumPlan(true)
    try {
      const { data: { confirmation_url: confirmationUrl } } = await backendRequest({
        method: 'POST',
        url: '/shopify/subscriptions',
        appBridge,
        data: {
          company_id: companyId,
          plan: 'premium'
        }
      })
      if (confirmationUrl) {
        window.open(confirmationUrl, '_top')
      }
    } catch (error) {
      console.error('Failed to create premium subscription.', error)
    } finally {
      setLoadingPremiumPlan(false)
    }
  }

  const premiumPlanButtonLabel = subscriptionPlan === 'premium' ? t('button.current_plan') : t('button.upgrade')
  const isPremiumPlanDisabled = subscriptionPlan === 'premium'
  const freePlanButtonLabel = subscriptionPlan === 'free' ? t('button.current_plan') : t('button.downgrade')
  const isFreePlanDisabled = subscriptionPlan === 'free' || !subscriptionPlan

  const premiumPlanItems = [
    t('plan.bom.starter.items.stock_adjustment_material'),
    t('plan.bom.starter.items.stock_adjustment_bundle'),
    t('plan.bom.starter.items.materials'),
    t('plan.bom.starter.items.bundles'),
    t('plan.bom.starter.items.productions')
  ]

  const freePlanItems = [
    t('plan.bom.free.items.stock_adjustment_material'),
    t('plan.bom.free.items.stock_adjustment_bundle'),
    t('plan.bom.free.items.materials'),
    t('plan.bom.free.items.bundles'),
    t('plan.bom.free.items.productions')
  ]

  return (
    <Page>
      { companyId
        ? (
          <InlineStack gap="400" align="center">
            <PlanCard
              title={t('plan.bom.free.title')}
              price={t('plan.bom.free.price')}
              items={freePlanItems}
              buttonLabel={freePlanButtonLabel}
              handleLoading={loadingFreePlan}
              handlePlanSelection={handleFreePlanSelection}
              disabled={isFreePlanDisabled}
        />
            <PlanCard
              title={t('plan.bom.starter.title')}
              price={t('plan.bom.starter.price')}
              frequency={t('plan.bom.starter.month')}
              items={premiumPlanItems}
              trial={t('plan.bom.starter.trial')}
              buttonLabel={premiumPlanButtonLabel}
              handleLoading={loadingPremiumPlan}
              handlePlanSelection={handlePremiumPlanSelection}
              disabled={isPremiumPlanDisabled}
        />
          </InlineStack>)
        : (
          <SkeletonPage>
            <InlineStack gap="400" align="center">
              <Card>
                <Box minHeight='100px' minWidth="350px" padding='300' />
              </Card>
              <Card>
                <Box minHeight='100px' minWidth="350px" padding='300' />
              </Card>
            </InlineStack>
          </SkeletonPage>
          )
      }
    </Page>
  )
}
