import {
  Button,
  Card,
  Text,
  Box,
  InlineGrid,
  Tooltip,
  BlockStack
} from '@shopify/polaris'
import { ArrowRightIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'
import { currencyRoundedFormatter } from '@shared/utils/formatter'
import { formatTooltip } from '@shared/utils/tooltipFormatter'

export const InventoryCard = ({ title, totalSellPrice, description, tone, totalProducts, onCardClick, tooltipStatus, tooltipVars = null }) => {
  const { t } = useTranslation()

  const formattedTooltipLines = tooltipStatus ? formatTooltip(tooltipStatus, tooltipVars) : ''

  return (
    <Card title={title} sectioned padding='500'>
      <Box padding='200' minHeight="40px">
        <Tooltip
          content={
            <Box padding='200' paddingBlockStart='300' paddingBlockEnd='300'>
              <BlockStack gap='300'>
                <Text variant="headingMd">
                  {t('inventory_card.loss_sales_tooltip')}
                </Text>
                <Text>
                  {t('inventory_card.loss_sales_tooltip_description')}
                </Text>
              </BlockStack>
            </Box>
          }
          hasUnderline
          preferredPosition='below'
        >
          <Text variant='headingLg' fontWeight='bold' as="span">
            {(totalSellPrice && currencyRoundedFormatter.format(totalSellPrice))}
          </Text>
        </Tooltip>
      </Box>
      <InlineGrid columns={['twoThirds', 'oneThird']}>
        <Box padding='200'>
          {
            tooltipStatus
              ? (
                <Tooltip
                  content={
                    <Box padding='200' paddingBlockStart='300' paddingBlockEnd='300'>
                      <BlockStack gap='300'>
                        <Text variant="headingMd">
                          {description}
                        </Text>
                        <BlockStack>
                          {formattedTooltipLines.map((line, index) => (
                            <Text key={index}>{line}</Text>
                          ))}
                        </BlockStack>
                      </BlockStack>
                    </Box>
                    }
                  hasUnderline
                  preferredPosition='below'
                  >
                  <Text as='span' variation='subdued'>{description}</Text>
                </Tooltip>
                )
              : (
                <Text as='h4' variation='subdued'>{description}</Text>
                )
          }
          <Text variant='headingXs' fontWeight='normal' tone={tone}>
            &#9632; {totalProducts} products
          </Text>
        </Box>
        <Button
          variant='primary'
          icon={ArrowRightIcon}
          onClick={onCardClick}
          disabled={totalProducts === 0}
        />
      </InlineGrid>
    </Card>
  )
}
