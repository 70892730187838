import { useContext } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import ShopifyLayout from '@shared/layouts/ShopifyLayout'
import ReplenishmentView from '@shared/views/ReplenishmentView'
import { DataUpdateContext, DataUpdateProvider } from '@shared/context/context'
import ErrorPage from '@shared/components/ErrorPage'
import { ShopifySubcriptionReturn } from '@shared/views/ShopifySubcriptionReturn'
import { PlanSelectionView } from '@replen/views/PlanSelectionView'
import { WelcomeView } from '@replen/views/WelcomeView'
import { SettingView } from '@replen/views/SettingView'
import './App.css'

function App () {
  return (
    <div className='App'>
      <DataUpdateProvider>
        <RouteManager />
      </DataUpdateProvider>
    </div>
  )
}

function RouteManager () {
  const { isLogin, landingUrl } = useContext(DataUpdateContext)

  if (isLogin === false) {
    return <ErrorPage errorCode='401' />
  }

  return (
    <Router>
      <Routes>
        <Route path='/shopify/subscription/return' element={<ShopifySubcriptionReturn navigateUrl='/replenishment'/>} />
        <Route path='*' element={<Navigate to={landingUrl} replace />} />
        <Route path='/welcome' element={<ShopifyLayout><WelcomeView /></ShopifyLayout>} />
        <Route path='/plan-selection' element={<ShopifyLayout><PlanSelectionView /></ShopifyLayout>} />
        <Route path='/settings' element={<ShopifyLayout><SettingView /></ShopifyLayout>} />
        <Route path='/replenishment' element={<ShopifyLayout><ReplenishmentView /></ShopifyLayout>} />
        <Route path='/401' element={<ErrorPage errorCode='401' />} />
        <Route path='/404' element={<ErrorPage errorCode='404' />} />
        <Route path='/500' element={<ErrorPage errorCode='500' />} />
      </Routes>
    </Router>
  )
}

export default App
