import { useState, useEffect, useCallback, useContext } from 'react'
import { cronJobService } from '@shared/services/cronJobService'
import { companyService } from '@shared/services/companyService'
import { DataUpdateContext } from '@shared/context/context'
import { getCronDays, getCronHour, translateCronJobFormat } from '@shared/utils'

export const useAlerts = () => {
  const [selectedDayOptions, setSelectedDayOptions] = useState([])
  const [selectedHourOptions, setSelectedHourOptions] = useState([])
  const [emailAddress, setEmailAddress] = useState('')
  const [dailyVelocityThreshold, setDailyVelocityThreshold] = useState()
  const [replenishNowDayThreshold, setReplenishNowDayThreshold] = useState()
  const [replenishSoonDayThreshold, setReplenishSoonDayThreshold] = useState()
  const [leadTime, setLeadTime] = useState()
  const [minDaysInventory, setMinDaysInventory] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const { companyId, appBridge, companyParams } = useContext(DataUpdateContext)

  const fetchSettings = useCallback(async () => {
    if (!companyId) {
      return
    }

    setLoading(true)
    try {
      if (companyParams.dailyVelocityThreshold >= 0) setDailyVelocityThreshold(companyParams.dailyVelocityThreshold)
      if (companyParams.replenishNowDayThreshold >= 0) setReplenishNowDayThreshold(companyParams.replenishNowDayThreshold)
      if (companyParams.replenishSoonDayThreshold >= 0) setReplenishSoonDayThreshold(companyParams.replenishSoonDayThreshold)
      if (companyParams.leadTime >= 0) setLeadTime(companyParams.leadTime)
      if (companyParams.minDaysInventory >= 0) setMinDaysInventory(companyParams.minDaysInventory)
      const data = await cronJobService.fetchAlertSettings(companyId, appBridge)
      if (data.total > 0 && data.cronJobs[0].active === true) {
        setSelectedDayOptions(getCronDays(data.cronJobs[0].schedule))
        setSelectedHourOptions([getCronHour(data.cronJobs[0].schedule)])
        setEmailAddress(data.cronJobs[0].data.email)
      }
    } catch (err) {
      setError('Failed to fetch alert settings')
      console.error('An error occurred while fetching alert settings:', err)
    } finally {
      setLoading(false)
    }
  }, [companyId, companyParams, appBridge])

  const saveSettings = useCallback(async () => {
    try {
      const schedule = translateCronJobFormat(selectedDayOptions, selectedHourOptions)
      const data = await cronJobService.saveAlertSettings(companyId, appBridge, schedule, emailAddress)
      await companyService.saveCompany({ companyId, appBridge, update: { params: { dailyVelocityThreshold, replenishNowDayThreshold, replenishSoonDayThreshold, leadTime, minDaysInventory } } })
      setSelectedDayOptions(getCronDays(data.schedule))
      setSelectedHourOptions([getCronHour(data.schedule)])
      setEmailAddress(data.data.email)
      return { success: true, message: 'Settings saved successfully' }
    } catch (err) {
      setError('Failed to save alert settings')
      console.error('An error occurred while saving alert settings:', err)
      return { success: false, message: 'Failed to save settings' }
    }
  }, [companyId, appBridge, selectedDayOptions, selectedHourOptions, emailAddress, dailyVelocityThreshold, replenishNowDayThreshold, replenishSoonDayThreshold, leadTime, minDaysInventory])

  useEffect(() => {
    fetchSettings()
  }, [fetchSettings])

  return {
    selectedDayOptions,
    setSelectedDayOptions,
    selectedHourOptions,
    setSelectedHourOptions,
    emailAddress,
    setEmailAddress,
    dailyVelocityThreshold,
    setDailyVelocityThreshold,
    replenishNowDayThreshold,
    setReplenishNowDayThreshold,
    replenishSoonDayThreshold,
    setReplenishSoonDayThreshold,
    leadTime,
    setLeadTime,
    minDaysInventory,
    setMinDaysInventory,
    loading,
    error,
    saveSettings,
    fetchSettings
  }
}
