import { useState, useCallback } from 'react'
import { Card, Text, Select, BlockStack } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

export const StatusCard = ({ onChange, initialValue }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(initialValue)

  const handleSelectChange = useCallback((value) => {
    setSelected(value)
    onChange(value)
  }, [onChange])

  const options = [
    {
      label: t('status_card.statuses.active'),
      value: 'active'
    },
    {
      label: t('status_card.statuses.draft'),
      value: 'draft'
    }
  ]

  return (
    <Card>
      <BlockStack gap='200'>
        <Text as="h2" variant="headingMd">
          {t('status_card.title')}
        </Text>
        <Select
          label=''
          options={options}
          value={selected}
          onChange={handleSelectChange}
        />
      </BlockStack>
    </Card>
  )
}
