import { backendRequest } from '@shared/utils/axiosInstance'

export const fetchProducts = async (companyId, appBridge) => {
  try {
    const response = await backendRequest({
      method: 'GET',
      url: `/obius/products?company_id=${companyId}&sort=product_name,product_variant_name&limit=10000`,
      data: {},
      appBridge
    })
    return response.data.products
  } catch (error) {
    throw new Error('Error fetching products: ' + error.message)
  }
}
