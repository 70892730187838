import React from 'react'
import {
  Page,
  Card,
  Text,
  BlockStack,
  Link,
  Button,
  InlineStack,
  List
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EcosystemCard } from '@shared/components/EcosystemCard'
import { HELP_DESK_URL, OBIUS_URL } from '@shared/utils/constant'

export const WelcomeView = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleNavigateToNewBundle = () => {
    navigate('/bundles/new')
  }

  return (
    <Page
      hasSubtitleMaxWidth
      title={t('welcome.bom.title')}
    >
      <BlockStack gap='400'>
        <Card sectioned>
          <BlockStack gap='300' inlineAlign="start">
            <Link url={OBIUS_URL} target="_blank">
              Obius.co
            </Link>
            <Text variant="headingXl" as="h2">
              {'👋 ' + t('welcome.bom.intro_card.title')}
            </Text>
            <Text variant="headingSm" as="p">
              {t('welcome.bom.intro_card.content')}
            </Text>
            <Text variant="headingLg" as="h3">
              {t('welcome.bom.intro_card.subtitle1')}
            </Text>
            <List>
              <List.Item>{t('welcome.bom.intro_card.features.component_stock_adjustment')}</List.Item>
              <List.Item>{t('welcome.bom.intro_card.features.bundle_stock_adjustment')}</List.Item>
            </List>
            <Text variant="headingLg" as="h3">
              {t('welcome.bom.intro_card.subtitle2')}
            </Text>
            <List type='number'>
              <List.Item>{t('welcome.bom.intro_card.steps.create')}</List.Item>
              <List.Item>{t('welcome.bom.intro_card.steps.sell')}</List.Item>
              <List.Item>{t('welcome.bom.intro_card.steps.we_handle')}</List.Item>
            </List>
            <Button
              variant="primary"
              onClick={handleNavigateToNewBundle}
            >
              {t('bundles.create_bundle')}
            </Button>
          </BlockStack>
        </Card>
        <EcosystemCard isBomInstalled />
        <Card sectioned>
          <BlockStack gap='300' inlineAlign="start">
            <Text variant='headingXl' as="h2">
              {t('welcome.bom.setup_card.title')}
            </Text>
            <Text variant='headingSm' as="p">
              {t('welcome.bom.setup_card.content')}
            </Text>
            <InlineStack gap='300' blockAlign='center'>
              <Button
                variant="primary"
                url={HELP_DESK_URL}
                external={true}
              >
                {t('button.send_us_a_message')}
              </Button>
              <Button
                url='https://meetings.hubspot.com/maxime-lavoie/obius-15min?uuid=0e6235af-29b5-4859-8b8f-5d9da4b33ba5'
                external={true}
              >
                {t('button.book_a_call')}
              </Button>
              <Link url={OBIUS_URL} target="_blank">
                {t('link.visit_our_website')}
              </Link>
            </InlineStack>
          </BlockStack>
        </Card>
      </BlockStack>
    </Page>
  )
}
